<template>
  <div class="section">
    <cust-header
      class="header"
      :data="clueInfoDetail"
      :diminish="true"
    />
    <tab-composite
      ref="tabComposite"
      class="main"
      fixed-height="140"
      :need-btn="needBtn"
      :tab-list="tabList"
      :load-enable="loadEnable"
      :refresh-enable="loadEnable"
      :need-border="false"
      @getIndex="getIndex"
    >
      <ul
        slot="1"
        class="list-wrapper"
      >
        <clue-info ref="clueInfo" />
      </ul>
      <ul
        slot="2"
        class="list-wrapper"
      >
        <follow-info
          v-for="(item, indexs) of trackInfoDetailList"
          :key="indexs"
          :data="item"
        />
        <error-page v-if="!trackInfoDetailList.length" />
      </ul>
      <ul
        v-if="$route.params.type === '1'"
        slot="3"
        class="list-wrapper"
      >
        <sales-info ref="salesInfo" />
      </ul>
    </tab-composite>
    <div class="bottom-postion">
      <cube-button
        v-if="btnAid.includes('addfollow')"
        class="btn btns"
        @click="toAddFollow"
      >
        添加跟进
      </cube-button>
      <cube-button
        v-if="btnAid.includes('transfer')"
        class="btn btns"
        @click="toTree"
      >
        转移
      </cube-button>
    </div>
  </div>
</template>

<script >
import tabComposite from '_c/tab-composite';
import custHeader from '_c/cust-header';
import followInfo from '@/views/clue/components/follow-info';
import clueInfo from '@/views/clue/components/clue-info';
import salesInfo from '@/views/clue/components/sales-info';
import errorPage from '_c/errorPage';
import { findValueByKey } from '@/lib/until';
import { clueDetail } from '_api/clue';
import btnAuth from '@/mixins/btnAuth';
import { mapGetters } from 'vuex';

export default {
  name: 'ClueDetails',
  components: {
    tabComposite,
    custHeader,
    followInfo,
    clueInfo,
    salesInfo,
    errorPage
  },
  mixins: [btnAuth],
  data() {
    return {
      hidden: false,
      clueInfoDetail: {},
      trackInfoDetailList: [{}],
      tabList: [{
        label: '线索信息',
        value: 0,
        num: '99'
      }, {
        label: '跟进信息',
        value: 1,
        num: '99+'
      }],
      sellUser: {},
      index: 0,
      loadEnable: false
    };
  },
  computed: {
    ...mapGetters('clue', ['aidList']),
    ...mapGetters(['dictList']),
    needBtn() {
      if (this.btnAid.includes('addfollow') || this.btnAid.includes('transfer')) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.getTabList();
    this.getDetail();
  },
  methods: {
    getDetail() {
      clueDetail(this.$route.params.clueId).then((res) => {
        if (res.flag) {
          // 头部信息
          let clueInfoDetail = res.data.clueInfoDetail;
          this.clueInfoDetail = {
            title: clueInfoDetail.clueName,
            clueStatus: clueInfoDetail.clueStatus,
            status: findValueByKey(this.dictList.clueStatus, clueInfoDetail.clueStatus),
            tagList: [
              `${findValueByKey(this.dictList.clueSource, clueInfoDetail.clueSource)}`
            ]
          };
          // 线索信息
          this.$nextTick(() => {
            this.$refs.clueInfo.getData(res.data.clueInfoDetail);
          });
          // 跟进信息
          this.trackInfoDetailList = res.data.trackInfoDetailList;
          this.trackInfoDetailList.forEach(item => {
            item.trackType = findValueByKey(this.dictList.trackType, item.trackType);
          });
          // 负责销售
          this.sellUser = res.data.sellUserInfoDetail;
          if (this.$refs.salesInfo) this.$refs.salesInfo.getData(res.data.sellUserInfoDetail);
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    getIndex(index) {
      this.index = index;
    },
    getTabList() {
      let type = this.$route.params.type;
      if (type === '1') {
        this.tabList.push({label: '负责销售', value: 2});
      }
      this.getAuthBtn(type);
    },
    toAddFollow() {
      if ([1, 2, 4].includes(this.clueInfoDetail.clueStatus)) {
        this.$router.push(
          {
            name: 'clue-follow',
            params: {
              clueId: this.$route.params.clueId,
              clueName: this.clueInfoDetail.title
            }
          }
        );
      } else {
        this.$showToast('未跟进、跟进中和失效的可以添加');
      }
    },
    toTree() {
      this.$router.push(
        {
          name: 'tree',
          params: {
            routerName: this.$route.name,
            typeName: 'transferSales',
            id: this.$route.params.clueId,
            sourceOrgId: this.sellUser.orgId,
            sourceUserId: this.sellUser.userId
          }
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped src="@/assets/css/components/btn-bottom.scss"></style>
<style  lang="scss"  scoped>
// .section{
//   // display: flex;
//   // flex-direction: column;
//   // height: 100%;
//   // .header{
//   //   width:100%;
//   //   height:168px;
//   // }
//   // .main{
//   //   flex:1;
//   // }
// }
.header{
  width:100%;
  height:140px;
}
</style>
