<template>
  <div class="card-box">
    <div class="card-cont">
      <div class="card-time">
        <div class="card-data">{{ data.trackDate && data.trackDate.substring(5 , data.trackDate.length) }}</div>
        <div class="card-year">{{ data.trackDate && data.trackDate.substring(0 , 4) }}</div>
      </div>
      <div class="card-content">
        <div class="card-conts">
          <div class="card-point">
            <div class="card-border">
              <div class="card-circle"></div>
            </div>
            <div class="card-line"></div>
          </div>
          <div class="card-detail">
            <div class="card-a">{{ data.trackType }}</div>
            <div class="card-b">{{ data.trackRemark }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object
    }
  }
};
</script>

<style lang="scss" scoped>
.card-box{
  width:100%;
  // height:78px;
  background-color: $color-FFF;
  .card-cont{
    display: flex;
    justify-content: flex-start;
    padding: 0 14.5px;
    text-align: left;
    .card-time{
      .card-data{
        margin-bottom: 10px;
        color:$color-333;
        font-weight: 600;
        font-size: 18px;
      }
      .card-year{
        color:$color-999;
        font-size:$font-12;
      }
    }
    .card-content{
      flex: 1;
      margin-left:25px;
      .card-conts{
        display: flex;
        justify-content: flex-start;
        min-height: 78px;
        .card-point{
          display: flex;
          flex-direction: column;
          width: 18px;
          .card-border{
            width:18px;
            height: 18px;
            background-color: $color-FFF;
            border: 1px solid $color-font;
            border-radius: 50%;
            .card-circle{
              width: 8px;
              height: 8px;
              margin: 5px auto;
              background: $color-font;
              border-radius: 50%;
            }
          }
          .card-line{
            flex: 1;
            width: 1px;
            margin: 0 auto;
            background: $color-E6;
          }
        }
        .card-detail{
          flex:1;
          margin-left:25px;
          text-align: left;
          .card-a{
            margin-bottom:9px;
            color:$color-333 ;
            font-weight: 600;
            font-size:$font-16;
          }
          .card-b{
            margin-bottom: 10px;
            color:$color-999 ;
            font-size:$font-14;
            line-height: 18px;
            word-break: break-all;
          }
        }
      }
    }
  }

}
.card-box:first-child{
  padding-top:24px;
}
.card-box:last-child{
  padding-bottom:24px;
}

</style>
