<template>
  <div class="cust-box">
    <div class="cust-content">
      <view-match :list="basicList"></view-match>
    </div>
  </div>
</template>

<script>
import viewMatch from '_c/view-match';
import { toArrays } from '@/lib/until.js';

export default {
  data() {
    return {
      basicData: {
        userTitle: {
          title: '负责销售',
          value: '张三'
        },
        orgTitle: {
          title: '所属部门',
          value: '销售一组'
        },
        branchOrgTitle: {
          title: '分公司',
          value: '杭州分公司'
        },
        createStaffTitle: {
          title: '创建人',
          value: '张三'
        },
        createTime: {
          title: '创建时间',
          value: '2020-09-09'
        }
      }
    };
  },
  computed: {
    basicList() { // 对象是引用类型，传递的是引用地址，所以两个数组引用的是同一个对象，只要其中一个数组改变，就会导致对象改变，进而另一个引用的数组也会改。
      return toArrays(this.basicData);
    },
    icList() { // 对象是引用类型，传递的是引用地址，所以两个数组引用的是同一个对象，只要其中一个数组改变，就会导致对象改变，进而另一个引用的数组也会改。
      return toArrays(this.icData);
    }
  },
  methods: {
    getData(data) {
      for (let i in this.basicData) {
        this.basicData[i].value = data[i];
      }
    }
  },
  components: {
    viewMatch
  }
};
</script>

<style lang="scss" scoped>
.cust-box{
  width:100%;
  background-color: $color-FFF;
  text-align: left;
  overflow: hidden;
  .cust-content{
    margin: 22px 14px 0 14px;
    .cust-title{
      font-size:$font-14;
      color:$color-333;
      font-weight: 600;
      margin-bottom: 24px;
    }
  }
}

</style>
