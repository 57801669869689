<template>
  <div class="cust-box">
    <div class="cust-content">
      <view-match :list="basicList" />
    </div>
  </div>
</template>

<script>
import viewMatch from '_c/view-match';

import { toArrays } from '@/lib/until.js';

export default {
  components: {
    viewMatch
  },
  data() {
    return {
      basicData: {
        clueName: {
          title: '线索名称',
          value: ''
        },
        custAddress: {
          title: '客户地址',
          value: ''
        },
        contactsName: {
          title: '联系人姓名',
          value: ''
        },
        contactsPost: {
          title: '职位',
          value: ''
        },
        contactsMobile: {
          title: '手机',
          value: '',
          callPhone: true
        },
        fixedPhone: {
          title: '固定电话',
          value: '',
          callPhone: true
        },
        contactsEmail: {
          title: '联系人邮箱',
          value: ''
        },
        contactsWechat: {
          title: '联系人微信',
          value: ''
        },
        contactsQq: {
          title: '联系人QQ',
          value: ''
        },
        contactsAddress: {
          title: '联系人地址',
          value: ''
        },
        remark: {
          title: '备注',
          value: ''
        }
      }
    };
  },
  computed: {
    basicList() { // 对象是引用类型，传递的是引用地址，所以两个数组引用的是同一个对象，只要其中一个数组改变，就会导致对象改变，进而另一个引用的数组也会改。
      return toArrays(this.basicData);
    },
    icList() { // 对象是引用类型，传递的是引用地址，所以两个数组引用的是同一个对象，只要其中一个数组改变，就会导致对象改变，进而另一个引用的数组也会改。
      return toArrays(this.icData);
    }
  },
  methods: {
    getData(data) {
      for (const i in this.basicData) {
        this.basicData[i].value = data[i];
      }
      this.basicData.custAddress.value = (data.custProvince ? data.custProvince : '') + (data.custCity ? data.custCity : '') + (data.custArea ? data.custArea : '') + (data.custAddr4 ? data.custAddr4 : '');
      this.basicData.contactsAddress.value = (data.contactsProvince ? data.contactsProvince : '') + (data.contactsCity ? data.contactsCity : '') + (data.contactsArea ? data.contactsArea : '') + (data.contactsAddr4 ? data.contactsAddr4 : '');
    }
  }
};
</script>

<style lang="scss" scoped>
.cust-box{
  width:100%;
  overflow: hidden;
  text-align: left;
  background-color: $color-FFF;
  .cust-content{
    margin: 22px 14px 0 14px;
    .cust-title{
      margin-bottom: 24px;
      color:$color-333;
      font-weight: 600;
      font-size:$font-14;
    }
  }
}

</style>
